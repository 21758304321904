import { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AddIcon, ArrowPersonIcon } from 'assets/Assets';
import { Button } from 'ITcomponents/index';
import { ErrorPage, AnswersMap, QuestionMap } from 'ui/index';
import {
  addChoice,
  addQuestion,
  deleteChoice,
  deleteQuestion,
  setForm,
  updateQuestionDetail,
  selectForm,
} from 'slices/index';
import { IQuestion } from 'intarfaces/form/createForm';
import { useCreateFormMutation } from 'slices/forms/formsApi';
import { changeHeaderStatus } from 'slices/user/userSlice';
import { RootState } from 'slices/store/store';
import styles from './CreatingForms.module.scss';


const CreatingForms = () => {
  const dispatch = useDispatch();
  const form = useSelector(selectForm);
  const token = localStorage.getItem('access');
  const navigate = useNavigate();
  const [createForm] = useCreateFormMutation();
  const creator = useSelector((state: RootState) => state.user.user.user_id);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    document.title = 'Создание формы';
    dispatch(changeHeaderStatus('answers'));
  }, [dispatch]);

  const clearErrorMessage = () => {
    setTimeout(() => {
      setErrorMessage('');
    }, 2500);
  };

  const handleAddQuestion = () => {
    const newQuestion: IQuestion = {
      id: 0,
      question_type: 'TEXT',
      question: '',
      required: false,
      choices: [],
    };
    dispatch(addQuestion(newQuestion));
  };

  const handleAddChoice = (questionIndex: number, value: string) => {
    dispatch(addChoice({ questionIndex, value }));
  };

  const handleDeleteQuestion = (questionIndex: number) => {
    dispatch(deleteQuestion(questionIndex));
  };

  const handleChangeQuestionDetail = (questionIndex: number, field: string, value: string | boolean) => {
    dispatch(updateQuestionDetail({ questionIndex, field, value }));
  };

  const handleDeleteChoice = (questionIndex: number, choiceIndex: number) => {
    dispatch(deleteChoice({ questionIndex, choiceIndex }));
  };

  const handleChangeChoice = (questionIndex: number, choiceIndex: number, value: string) => {
    dispatch(updateQuestionDetail({ questionIndex, field: 'choices', choiceIndex, value }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!form.title) {
      setErrorMessage('Название формы обязательно');
      clearErrorMessage();
      return;
    }

    for (let question of form.questions) {
      if (!question.question) {
        setErrorMessage('Все вопросы должны иметь название');
        clearErrorMessage();
        return;
      }
    }

    const modifiedQuestions = form.questions.map(question => {
      return {
        ...question,
        choices: question.choices.map(choice => ({ choice, is_correct: true })),
      };
    });

    try {
      await createForm({
        title: form.title,
        description: form.description,
        questions: modifiedQuestions,
        creator,
      }).unwrap();

      navigate('/all_forms');
    } catch (error) {
      setErrorMessage('Ошибка при создании формы');
      clearErrorMessage();
    }
  };

  return (
    token ? (
      <>
        <div className={styles.create__container}>
          <div className={styles.create__container_input}>
            <form onSubmit={handleSubmit} className={styles.create__form}>
              <Button type="button" className="form__back__button"
                      onClick={() => navigate('/all_forms')}><ArrowPersonIcon
                className={styles.create__nav__back}></ArrowPersonIcon></Button>
              <div className={styles.create__block}>
                <input className={styles.create__input}
                       onChange={(e) => dispatch(setForm({ ...form, title: e.target.value }))}
                       value={form.title}
                       placeholder="Название формы" required
                />
                <input className={styles.create__description__input}
                       onChange={(e) => dispatch(setForm({ ...form, description: e.target.value }))}
                       value={form.description}
                       placeholder="Описание" required
                />
              </div>
              {form.questions.map((question, qIndex) => (
                <QuestionMap
                  key={qIndex}
                  qIndex={qIndex}
                  question={question}
                  handleChangeQuestionDetail1={(e) => handleChangeQuestionDetail(qIndex, 'question', e.target.value)}
                  handleDeleteQuestion={() => handleDeleteQuestion(qIndex)}
                  handleChangeQuestionDetail2={(e) => handleChangeQuestionDetail(qIndex, 'required', e.target.checked)}
                  handleChangeQuestionType={(e) => handleChangeQuestionDetail(qIndex, 'question_type', e.target.value)}
                  handleAddChoice={() => handleAddChoice(qIndex, '')}
                >
                  <>
                    {question.choices.map((choice, cIndex) => (
                      <AnswersMap
                        key={cIndex}
                        cIndex={cIndex}
                        qIndex={qIndex}
                        choice={choice}
                        handleChangeChoice={(e) => handleChangeChoice(qIndex, cIndex, e.target.value)}
                        handleDeleteChoice={() => handleDeleteChoice(qIndex, cIndex)} />
                    ))}
                  </>
                </QuestionMap>
              ))}

              <div className={styles.create__end__btns}>
                <Button type="button" className="blue__button"
                        onClick={handleAddQuestion}><AddIcon />Добавить вопрос</Button>
                <Button type="submit" className="blue__button"
                        onClick={handleSubmit}>Создать форму</Button>
              </div>
            </form>
            {errorMessage &&
              <p className={styles.create__message__error}>{errorMessage}</p>
            }
          </div>
        </div>
      </>
    ) : (
      <ErrorPage />
    )
  );
};

export default CreatingForms;